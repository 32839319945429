(function() {
    'use strict';

    angular
        .module('publicGatewayApp')
        .controller('PublicProfileDialogController', PublicProfileDialogController);

    PublicProfileDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'DataUtils', 'entity', 'PublicProfile', 'Gender', 'SeekingGender', 'Country', 'Region', 'City', 'Photo', 'SeekingSettings'];

    function PublicProfileDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, DataUtils, entity, PublicProfile, Gender, SeekingGender, Country, Region, City, Photo, SeekingSettings) {
        var vm = this;

        vm.publicProfile = entity;
        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.genders = Gender.query();
        vm.seekinggenders = SeekingGender.query();
        vm.countries = Country.query();
        vm.regions = Region.query();
        vm.cities = City.query();
        vm.photos = Photo.query();
        vm.seekingsettings = SeekingSettings.query({filter: 'publicprofile-is-null'});
        $q.all([vm.publicProfile.$promise, vm.seekingsettings.$promise]).then(function() {
            if (!vm.publicProfile.seekingSettings || !vm.publicProfile.seekingSettings.id) {
                return $q.reject();
            }
            return SeekingSettings.get({id : vm.publicProfile.seekingSettings.id}).$promise;
        }).then(function(seekingSettings) {
            vm.seekingsettings.push(seekingSettings);
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.publicProfile.id !== null) {
                PublicProfile.update(vm.publicProfile, onSaveSuccess, onSaveError);
            } else {
                PublicProfile.save(vm.publicProfile, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('publicGatewayApp:publicProfileUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        vm.setAvatar = function ($file, publicProfile) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        publicProfile.avatar = base64Data;
                        publicProfile.avatarContentType = $file.type;
                    });
                });
            }
        };

    }
})();
