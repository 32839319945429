(function() {
    'use strict';

    angular
        .module('publicGatewayApp')
        .factory('SeekingSettingsSearch', SeekingSettingsSearch);

    SeekingSettingsSearch.$inject = ['$resource'];

    function SeekingSettingsSearch($resource) {
        var resourceUrl =  'api/_search/seeking-settings/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
