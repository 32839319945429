(function() {
    'use strict';

    angular
        .module('publicGatewayApp')
        .controller('PublicProfileDetailController', PublicProfileDetailController);

    PublicProfileDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'PublicProfile', 'Gender', 'SeekingGender', 'Country', 'Region', 'City', 'Photo', 'SeekingSettings'];

    function PublicProfileDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, PublicProfile, Gender, SeekingGender, Country, Region, City, Photo, SeekingSettings) {
        var vm = this;

        vm.publicProfile = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('publicGatewayApp:publicProfileUpdate', function(event, result) {
            vm.publicProfile = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
