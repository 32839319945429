(function() {
    'use strict';

    angular
        .module('publicGatewayApp')
        .controller('ProfileCriterionItemController', ProfileCriterionItemController);

    ProfileCriterionItemController.$inject = ['$scope', '$state', 'ProfileCriterionItem', 'ProfileCriterionItemSearch'];

    function ProfileCriterionItemController ($scope, $state, ProfileCriterionItem, ProfileCriterionItemSearch) {
        var vm = this;

        vm.profileCriterionItems = [];
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            ProfileCriterionItem.query(function(result) {
                vm.profileCriterionItems = result;
                vm.searchQuery = null;
            });
        }

        function search() {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            ProfileCriterionItemSearch.query({query: vm.searchQuery}, function(result) {
                vm.profileCriterionItems = result;
                vm.currentSearch = vm.searchQuery;
            });
        }

        function clear() {
            vm.searchQuery = null;
            loadAll();
        }    }
})();
