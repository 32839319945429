(function() {
    'use strict';

    angular
        .module('publicGatewayApp')
        .factory('GeonameLocalizationSearch', GeonameLocalizationSearch);

    GeonameLocalizationSearch.$inject = ['$resource'];

    function GeonameLocalizationSearch($resource) {
        var resourceUrl =  'api/_search/geoname-localizations/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
