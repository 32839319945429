(function() {
    'use strict';

    angular
        .module('publicGatewayApp')
        .factory('OwnProfileSearch', OwnProfileSearch);

    OwnProfileSearch.$inject = ['$resource'];

    function OwnProfileSearch($resource) {
        var resourceUrl =  'api/_search/own-profiles/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
