(function() {
    'use strict';

    angular
        .module('publicGatewayApp')
        .controller('GeonameLocalizationDeleteController',GeonameLocalizationDeleteController);

    GeonameLocalizationDeleteController.$inject = ['$uibModalInstance', 'entity', 'GeonameLocalization'];

    function GeonameLocalizationDeleteController($uibModalInstance, entity, GeonameLocalization) {
        var vm = this;

        vm.geonameLocalization = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            GeonameLocalization.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
