(function() {
    'use strict';

    angular
        .module('publicGatewayApp')
        .controller('SeekingGenderDetailController', SeekingGenderDetailController);

    SeekingGenderDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'SeekingGender'];

    function SeekingGenderDetailController($scope, $rootScope, $stateParams, previousState, entity, SeekingGender) {
        var vm = this;

        vm.seekingGender = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('publicGatewayApp:seekingGenderUpdate', function(event, result) {
            vm.seekingGender = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
