(function() {
    'use strict';

    angular
        .module('publicGatewayApp')
        .controller('ProfileCriterionItemDetailController', ProfileCriterionItemDetailController);

    ProfileCriterionItemDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ProfileCriterionItem', 'ProfileCriterion'];

    function ProfileCriterionItemDetailController($scope, $rootScope, $stateParams, previousState, entity, ProfileCriterionItem, ProfileCriterion) {
        var vm = this;

        vm.profileCriterionItem = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('publicGatewayApp:profileCriterionItemUpdate', function(event, result) {
            vm.profileCriterionItem = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
