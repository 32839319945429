(function() {
    'use strict';

    angular
        .module('publicGatewayApp')
        .controller('SeekingGenderDeleteController',SeekingGenderDeleteController);

    SeekingGenderDeleteController.$inject = ['$uibModalInstance', 'entity', 'SeekingGender'];

    function SeekingGenderDeleteController($uibModalInstance, entity, SeekingGender) {
        var vm = this;

        vm.seekingGender = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            SeekingGender.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
